import axios from "axios";
import localforage from "localforage";
import { currentDatetime } from "@/utils/datetime-utils";

export default class ItemLocationUpdateOperationsRepository {

	constructor () {
		this.repositoryNamespace = "ITEM_LOCATION_UPDATE_OPERATIONS"
	}

	async getAll () {
		return localforage.getItem(this.repositoryNamespace).then(itemLocationUpdateOperations => itemLocationUpdateOperations ?? [])
	}

	async create (itemLocationUpdateOperationData) {
		let itemLocationUpdateOperation = {
			uuid: itemLocationUpdateOperationData.uuid,
			location: itemLocationUpdateOperationData.location,
			synced_at:  null,
			created_at: currentDatetime(),
		}

		if (navigator.onLine) {
			let requestParams = {
				uuid: itemLocationUpdateOperationData.uuid,
				location: itemLocationUpdateOperationData.location,
			}

			try {
				await axios.post("/item-location-update-operations/create", requestParams)
				itemLocationUpdateOperation.synced_at = currentDatetime()
			} catch (error) {
				// Some errors are handled with an interceptor
			}
		}

		let existingItemLocationUpdateOperations = await localforage.getItem(this.repositoryNamespace) ?? []
		existingItemLocationUpdateOperations.push(itemLocationUpdateOperation)

		return localforage.setItem(this.repositoryNamespace, existingItemLocationUpdateOperations)
	}

	async syncAll () {
		let existingItemLocationUpdateOperations = await localforage.getItem(this.repositoryNamespace) ?? []

		for (let i = 0; i < existingItemLocationUpdateOperations.length; i++) {
			let itemLocationUpdateOperation = existingItemLocationUpdateOperations[i]

			if (navigator.onLine && itemLocationUpdateOperation.synced_at == null) {
				let requestParams = {
					uuid: itemLocationUpdateOperation.uuid,
					location: itemLocationUpdateOperation.location,
				}

				try {
					await axios.post("/item-location-update-operations/create", requestParams)
					existingItemLocationUpdateOperations[i].synced_at = currentDatetime()
				} catch (error) {
					// Some errors are handled with an interceptor
				}
			}
		}

		return localforage.setItem(this.repositoryNamespace, existingItemLocationUpdateOperations)
	}

	// --------------------------------------------------

	async deleteAll () {
		return localforage.setItem(this.repositoryNamespace, [])
	}
}
<template>
	<div id="items-info-by-uuid-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Informazioni oggetto
			</h1>
		</div>

		<div v-if="loading">
			<div class="card mb-3">
				<div class="card-body">
					Caricamento in corso...

					<div class="progress mt-1">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="card mb-3">
				<div class="card-body">
					<p class="card-text">
						Codice scansionato: {{ scannedCode }}
					</p>
				</div>
			</div>

			<button class="btn w-100 mb-3" :class="scannedCode ? 'btn-success' : 'btn-primary'" type="button" @click="startQrScanner()" v-show="!showQrScanner">
				Scansiona codice
			</button>

			<div class="card mb-3" v-show="showQrScanner">
				<div class="card-body">
					<h4 class="card-title">
						Scansiona codice
					</h4>
					<canvas id="qr-scanner-canvas" style="border: solid 1px black; width: 100%;"></canvas>
				</div>
			</div>

			<div class="card mb-3" v-if="scannedCode && selectedItem == null">
				<div class="card-body">
					<p class="card-text">
						Nessun oggetto trovato.
					</p>
				</div>
			</div>

			<div class="card mb-3" v-if="scannedCode && selectedItem">
				<div class="card-body">
					<h4 class="card-title mb-0">
						{{ selectedItem.registry.name }}
						<br>
						<small class="text-muted">
							{{ selectedItem.registry_location.address }}
							-
							{{ selectedItem.registry_location.zip }}
							{{ selectedItem.registry_location.city }}
							({{ selectedItem.registry_location.province_code }})
						</small>
					</h4>
				</div>
				<div class="table-responsive">
					<table class="table table-bordered table-vmiddle mb-0">
						<tbody>
							<tr>
								<th class="text-nowrap" width="1%">Tipologia</th>
								<td>{{ selectedItem.object_type.name }}</td>
							</tr>
							<tr>
								<th class="text-nowrap" width="1%">Matricola</th>
								<td>{{ selectedItem.registration_number }}</td>
							</tr>
							<tr>
								<th class="text-nowrap" width="1%">Caratteristica</th>
								<td>
									<span class="badge rounded-pill bg-secondary" v-for="objectFeature in selectedItem.object_features" :key="objectFeature.id">
										{{ objectFeature.name }}
									</span>
								</td>
							</tr>
							<tr>
								<th class="text-nowrap" width="1%">Anno</th>
								<td>{{ selectedItem.date }}</td>
							</tr>
							<tr>
								<th class="text-nowrap" width="1%">Classe d'incendio</th>
								<td>{{ selectedItem.fire_class }}</td>
							</tr>
							<tr>
								<th class="text-nowrap" width="1%">Ultima revisione</th>
								<td>{{ formatDate(selectedItem.last_revision) }}</td>
							</tr>
							<tr>
								<th class="text-nowrap" width="1%">Ultimo collaudo</th>
								<td>{{ formatDate(selectedItem.last_testing) }}</td>
							</tr>
							<tr>
								<th class="text-nowrap" width="1%">
									<a href="#" @click.prevent="askUserForNewLocation()">Ubicazione</a>
								</th>
								<td>{{ selectedItem.location }}</td>
							</tr>
							<tr v-if="selectedItem.notes.length > 0">
								<td colspan="2">
									{{ selectedItem.notes }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import localforage from "localforage"
import jsQR from "jsqr"

import ItemsRepository from '@/repositories/items-repository'
import ItemLocationUpdateOperationsRepository from '@/repositories/item-location-update-operations-repository'
import { formatDate } from '@/utils/datetime-utils'

let itemsRepository = new ItemsRepository()
let itemLocationUpdateOperationsRepository = new ItemLocationUpdateOperationsRepository()

let itemsList = [] // Initialized here since we do not need it to be reactive

export default {
	name: "LabelReplacementWithQrCodeView",
	data () {
		return {
			loading: false,

			showQrScanner: false,
			scannedCode: "",
			selectedItem: null,
			qrVideo: null,
			qrCanvasElement: null,
			qrCanvas: null,
		}
	},
	computed: {

	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("USER").then(user => this.user = user || null),
			itemsRepository.getAll().then(items => itemsList = items),
		]).finally(() => this.loading = false)
	},
	beforeDestroy () {
		this.stopQrScanner()
	},
	methods: {
		formatDate: formatDate,
		findItemByUuid (uuid) {
			return itemsList.find(item => item.uuid == uuid)
		},
		startQrScanner () {
			this.showQrScanner = true

			this.qrVideo = document.createElement("video")
			this.qrCanvasElement = document.getElementById("qr-scanner-canvas")
			this.qrCanvas = this.qrCanvasElement.getContext("2d")

			navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(stream => {
				this.qrVideo.srcObject = stream
				this.qrVideo.setAttribute("playsinline", true) // Required to tell iOS safari we don't want fullscreen
				this.qrVideo.play()
				requestAnimationFrame(this.qrScannerCanvasCallback)
			})
		},
		stopQrScanner () {
			if (this.qrVideo && this.qrVideo.srcObject) {
				this.qrVideo.srcObject.getTracks().forEach(track => track.stop())
				this.qrVideo.srcObject = null
			}

			this.showQrScanner = false
		},
		drawLineOnQrScannerCanvas (begin, end, color) {
			this.qrCanvas.beginPath();
			this.qrCanvas.moveTo(begin.x, begin.y);
			this.qrCanvas.lineTo(end.x, end.y);
			this.qrCanvas.lineWidth = 4;
			this.qrCanvas.strokeStyle = color;
			this.qrCanvas.stroke();
		},
		qrScannerCanvasCallback () {
			if (this.qrVideo && this.qrVideo.readyState == this.qrVideo.HAVE_ENOUGH_DATA) {
				this.qrCanvasElement.height = this.qrVideo.videoHeight;
				this.qrCanvasElement.width = this.qrVideo.videoWidth;
				this.qrCanvas.drawImage(this.qrVideo, 0, 0, this.qrCanvasElement.width, this.qrCanvasElement.height);

				let imageData = this.qrCanvas.getImageData(0, 0, this.qrCanvasElement.width, this.qrCanvasElement.height)
				let scannedCode = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })

				if (scannedCode) {
					this.drawLineOnQrScannerCanvas(scannedCode.location.topLeftCorner, scannedCode.location.topRightCorner, "#FF3B58");
					this.drawLineOnQrScannerCanvas(scannedCode.location.topRightCorner, scannedCode.location.bottomRightCorner, "#FF3B58");
					this.drawLineOnQrScannerCanvas(scannedCode.location.bottomRightCorner, scannedCode.location.bottomLeftCorner, "#FF3B58");
					this.drawLineOnQrScannerCanvas(scannedCode.location.bottomLeftCorner, scannedCode.location.topLeftCorner, "#FF3B58");

					this.scannedCode = scannedCode.data
					this.selectedItem = this.findItemByUuid(scannedCode.data)

					this.stopQrScanner()
				}
			}

			requestAnimationFrame(this.qrScannerCanvasCallback)
		},
		askUserForNewLocation () {
			let location = prompt("Modifica ubicazione", this.selectedItem.location).trim()

			if (location != this.selectedItem.location) {
				this.selectedItem.location = location

				this.loading = true

				itemLocationUpdateOperationsRepository.create({
					uuid: this.selectedItem.uuid,
					location: location
				}).finally(() => this.loading = false)
			}
		},
	}
}
</script>